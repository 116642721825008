//MAINS
export const ROOT_PAGE = "/";
export const HOME_PAGE = "/dashboard";
export const PATIENTS_PAGE = "/dashboard/patients";
export const CREATE_PAGE = "/services";
export const SERVICE_PAGE = "/dashboard/patients/examination";
export const PAYMENT_PAGE = "/dashboard/patients/payment";
export const AGENDA_PAGE = "/dashboard/agenda";
export const MEDICINE_PAGE = "/dashboard/medicine";
export const REPORT_PAGE = "/dashboard/report";

// AUTHENTICATION
export const AUTH_PAGE = "/auths/sign-in";
export const REGISTER_PAGE = "/auths/register";

// SETTING
export const CLINIC_PAGE = "/dashboard/clinics";
export const DOCTOR_PAGE = "/dashboard/doctors";
export const BILLING_PAGE = "/dashboard/billings";
export const TREATMENT_PAGE = "/dashboard/treatments";
export const LOGOUT_PAGE = "/logout";

//FRAGMENTS
export const ADD_PATIENT_PAGE = "/dashboard/patients/create";
export const DETAIL_PATIENT_PAGE = "/patients";
export const CREATE_EXAMINATION_PAGE = "/create/create-examination";
export const CREATE_PRESCRIPTION_PAGE = "/create/create-prescription";
export const CREATE_TREATMENT_PAGE = "/create/create-plan-treatment";
export const CREATE_APPOINTMENT_PAGE = "/create/create-appointment";
